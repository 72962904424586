import React from "react";
import { Navbar, Image } from "react-bootstrap";

export default class AppNavbar extends React.Component {
  render() {
    return (
      <Navbar sticky="top" bg="white">
        <Navbar.Brand href="/">
          <Image src="/images/logo.svg" />
        </Navbar.Brand>
      </Navbar>
    );
  }
}

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AppNavbar from "./AppNavbar";
import AppFooter from "./AppFooter";
import Feature from "./Feature";
import Particles from "react-particles-js";
import "../css/home.css";

export default class Home extends React.Component {
  render() {
    return (
      <div>
        <AppNavbar />
        <div>
          <div className="section-banner">
            <Container>
              <Row>
                <Col sm={6} className="col-banner-left">
                  <h1>
                    We design &<br></br>build digital products
                  </h1>
                </Col>
                <Col sm={6} className="py-5">
                  <Particles
                    params={{
                      particles: {
                        number: {
                          value: 25,
                        },
                        size: {
                          value: 3,
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="section-services">
            <h2 className="my-4">Our services</h2>
            <Row>
              <Feature
                title="Mobile development"
                description="We create iOS & Android app tailored to your specification and
                  needs"
                imageSrc="./images/feature_mobile.jpg"
                imageAlt="mobile development"
              />
              <Feature
                title="Web development"
                description="We build user friendly, functional and responsive website"
                imageSrc="/images/feature_web.jpg"
                imageAlt="web development"
              />
              <Feature
                title="Training"
                description="We provide technical training and courses on mobile & web"
                imageSrc="/images/feature_training.jpg"
                imageAlt=""
              />
              <Feature
                title="Consulting"
                description="We provide consultancy services on mobile & web development"
                imageSrc="/images/feature_consulting.jpg"
                imageAlt="consulting"
              />
            </Row>
          </Container>
          <div className="section-contact">
            <h3 className="mb-3">Contact us</h3>
            <Button href="mailto:contact@mrmdigital.co" variant="light">
              contact@mrmdigital.co
            </Button>
          </div>
        </div>
        <AppFooter />
      </div>
    );
  }
}

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Surahs from "./Surahs";
import PrivacyPolicy from "./PrivacyPolicy";
import Acknowledgements from "./Acknowledgements";
import NotFound from "./NotFound";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/surahs" component={Surahs} />
      <Route exact path="/surahs/privacy-policy" component={PrivacyPolicy} />
      <Route
        exact
        path="/surahs/acknowledgements"
        component={Acknowledgements}
      />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;

import React from "react";
import { Col, Image } from "react-bootstrap";

const Feature = props => (
  <Col xs={12} sm={6} md={3} className="text-center">
    <Image fluid rounded src={props.imageSrc} alt={props.imageAlt} />
    <h4 className="mt-3">{props.title}</h4>
    <p className="text-secondary">{props.description}</p>
  </Col>
);

export default Feature;

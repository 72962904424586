import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "../css/surahs.css";

export default class Surahs extends React.Component {
  render() {
    return (
      <div>
        <div className="section-hero">
          <Container className="pt-4">
            <Row>
              <Col md={6} className="mt-5">
                <Image src="/images/icon_yasin.png" className="app-icon" />
                <h3 className="mt-3">Surah Yasin</h3>
                <Image src="/images/yasin_1.png" className="device" />
                <div className="my-4">
                  <Image
                    src="/images/button_appstore.svg"
                    className="mr-2 btn-download"
                  />
                  <Image
                    src="/images/button_google_play.svg"
                    className="btn-download"
                  />
                </div>
              </Col>
              <Col md={6} className="mt-5">
                <Image src="/images/icon_almulk.png" className="app-icon" />
                <h3 className="mt-3">Surah Al-Mulk</h3>
                <Image src="/images/almulk_1.png" className="device" />
                <div className="my-4">
                  <Image
                    src="/images/button_appstore.svg"
                    className="mr-2 btn-download"
                  />
                  <Image
                    src="/images/button_google_play.svg"
                    className="btn-download"
                  />
                </div>
              </Col>
            </Row>
            <h3 className="mt-5">Features</h3>
            <div>
              <ul>
                <li>Simple design with easy to read Quranic text.</li>
                <li>Option to resize text font size.</li>
                <li>
                  Toggle between verse by verse or surah page reading mode.
                </li>
                <li>Read translation in several languages.</li>
                <li>
                  Support for offline mode, read and listen even without
                  Internet access.
                </li>
                <li>Listen to recitation verse by verse.</li>
                <li>
                  Autoscrolling to current playing verse with option to pause
                  and skip to next or previous verse.
                </li>
                <li>Support landscape & portrait mode.</li>
              </ul>
            </div>
          </Container>
          <Container>
            <div className="section-contact">
              <h3 className="mb-3">Contact us</h3>
              <Button href="mailto:contact@mrmdigital.co">
                contact@mrmdigital.co
              </Button>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

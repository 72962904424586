import React from "react";
import { Container } from "react-bootstrap";
import "../css/footer.css";

const AppFooter = () => (
  <div className="footer">
    <Container className="py-5 text-center">
      Copyright © {new Date().getFullYear()} MRM Digital Solutions. All rights
      reserved.
    </Container>
  </div>
);

export default AppFooter;
